<template>
  <main>
    <section>
      <article class="about_us_article_one">
        <img src="../assets/big_images/pool1.jpg">
        <div class="about_us_article_one_description">
          <div class="container">
            <div class="row">
              <div class="col-md-9">
                <h1>Explore the unbeaten track, stay with us!</h1>
                <p class="mt-4">We love to travel and we want to share our excitement with you!</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_two pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <p
                class="italic-text"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Experience all the Pearl of Africa has to offer as you tour through our cities,
                National Parks, and Wildlife Reserves.
              </p>
            </div>
            <div class="col-md-8">
              <h2
                data-aos="fade-up"
                data-aos-duration="3000"
              >Adere Safari Lodges</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="3000"
                style="font-size: 1.4em;"
              >
                Adere Safari Lodge boasts of hand-made architecture by local craftsmen and comprises 20 en-suite rooms; an intimate African themed conference hall, a boardroom, a restaurant with jaw dropping views, a well stocked bar, and magniﬁcent gardens overlooking Kidepo Valley National Park. Adere Safari Lodge takes pride in its swimming pool carved out of big rocks.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_three pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_three_grid">
            <div>
              <div>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Adere, loosely means Calabash, in Iteso a local language in Uganda.
                  A calabash is an artifact made from the hard shell of a fruit of the gourd family
                  which is dried and hollowed out for serving or storing food and other items.
                  Adere, therefore, symbolizes the life-enriching experience and care you will
                  get while at Lodge and this is what makes our world turn - ensuring that every precious
                  moment spent with us is meaningful, rich, and beautiful. A warm welcome to the amazing
                  Adere Safari Lodge.
                </p>
              </div>
            </div>
            <div>
              <img
                src="../assets/images/bg1.jpg"
                class="w-100"
              >
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_five pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_five_grid">
            <div>
              <img
                src="../assets/big_images/Wildness.jpg"
                class="w-100"
              >
            </div>
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Who we are</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  You will love it here. We look forward to making your safari memorable, restful,
                  worthwhile and transformational. Whether it is a drive through the vast savannah of
                  Kidepo, a sunset dinner, a late night reading of a book, an early morning watch of
                  the sun as it rises, or a community walk and leisurely walk about, may your time at
                  Adere create beautiful Memories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.about_us_article_one {
  background-color: #f0f9eb;
  height: 100vh;
  width: 100%;
  position: relative;
}

.about_us_article_one img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.about_us_article_one_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.about_us_article_one_description h1 {
  color: white;
  font-size: 2.2em;
  font-weight: 600;
}

.about_us_article_one_description p {
  color: white;
  font-size: 1.2em;
  font-weight: 500;
}

.about_us_article_two {
  background-color: #f0f9eb;
}

.about_us_article_two .italic-text {
  color: grey;
  font-size: 1.8em;
  font-weight: 300;
  font-style: italic;
}

.about_us_article_two h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_three,
.about_us_article_five {
  background-color: #f0f9eb;
}

.about_us_article_three .about_us_article_three_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.about_us_article_three .about_us_article_three_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.about_us_article_three .about_us_article_three_grid img {
  border-radius: 5px;
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.about_us_article_three .about_us_article_three_grid h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_three .about_us_article_three_grid p {
  color: grey;
  font-size: 1em;
}

.about_us_article_five .about_us_article_five_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.about_us_article_five .about_us_article_five_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.about_us_article_five .about_us_article_five_grid img {
  border-radius: 5px;
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.about_us_article_five .about_us_article_five_grid h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_five .about_us_article_five_grid p {
  color: grey;
  font-size: 1em;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .about_us_article_three .about_us_article_three_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_three .about_us_article_three_grid > div:first-child {
    grid-auto-flow: row;
    grid-row: 2;
  }

  .about_us_article_three .about_us_article_three_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_three .about_us_article_three_grid p {
    font-size: 0.8em;
  }

  .about_us_article_five .about_us_article_five_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_five .about_us_article_five_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_five .about_us_article_five_grid p {
    font-size: 0.8em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .about_us_article_three .about_us_article_three_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_three .about_us_article_three_grid > div:first-child {
    grid-auto-flow: row;
    grid-row: 2;
  }

  .about_us_article_three .about_us_article_three_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_three .about_us_article_three_grid p {
    font-size: 0.8em;
  }

  .about_us_article_five .about_us_article_five_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_five .about_us_article_five_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_five .about_us_article_five_grid p {
    font-size: 0.8em;
  }
}
</style>